export interface MeunType<T> {
    name: string
    title: string
    icon: string
}

export class centerMeun {
    meunList: MeunType<number>[] = [
        {
            name: 'redeemCode',
            title: '兑换码',
            icon: 'duihuanma'
        },
        {
            name: 'feedback',
            title: '问题反馈',
            icon: 'fankui'
        },
        {
            name: 'about',
            title: '联系我们',
            icon: 'lianxi'
        },
        {
            name: 'orderList',
            title: '订单列表',
            icon: 'list'
        },
        {
            name: 'setup',
            title: '更多',
            icon: 'setup1'
        }
    ]
}

export interface ICode {
    code: number
    createAt: string
    id: number
    label: string
    type: string
    updateAt: string
    user_id: number
}

export class IngterDetail {
    list: ICode[] = []
}