export const days = () => {
    const Y = new Date().getFullYear()
    const M = new Date().getMonth() + 1
    const D = new Date().getDate()
    return Y + '-' + M + '-' + D
}

export const formatDays = (time: string) => {
    const Y = new Date(time).getFullYear()
    const M = new Date(time).getMonth() + 1
    const D = new Date(time).getDate()
    return Y + '-' + M + '-' + D
}
